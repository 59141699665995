import React, { FC } from 'react';
import Layouts from '../../components/layout';
import SectionTitle from '../../components/common/SectionTitle';
import './acknowledgementAndAcceptance.scss';
import TextDescription from '../../components/common/TextDescription';
import FixedButton from '../../components/common/FixedButton';
import SEO from '../../components/SEO';

const AcknowledgementAndAcceptance: FC = () => {
    return (
        <Layouts>
            <SEO
                pageTitle="Blackbird Lab - Privacy policy"
                pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
                pageImage="https://d255vd89djh8t2.cloudfront.net/privacy-policy/privacy-policy.png"
            />
            <section className="privacy-policy__wrapper">
                <SectionTitle type={'main'} title={'Privacy policy'} />
                <span className="privacy-policy__info">
                    The Blackbird Lab Privacy Policy was updated on <br />
                    August 22, 2023.
                </span>
                <div className="privacy-policy__section">
                    <TextDescription type="mb">
                        This Privacy Policy describes how personal information is collected, used,
                        and shared when you visit Blackbird Lab page (the “Website”), and which
                        applies to all visitors to this Website. In order to visit and use the
                        Website, you must agree to this document, which can be found at the bottom
                        of this page.
                    </TextDescription>
                    <TextDescription type="mb">
                        This Website collects some Personal Data from its Users.
                    </TextDescription>
                    <TextDescription type="mb">
                        Users may be subject to different protection standards and broader standards
                        may, therefore, apply to some. In order to learn more about the protection
                        criteria, Users can refer to the applicability section.
                    </TextDescription>
                    <TextDescription type="mb">
                        Users may be subject to different protection standards and broader standards
                        may, therefore, apply to some. In order to learn more about the protection
                        criteria, Users can refer to the applicability section.
                    </TextDescription>
                    <TextDescription type="mb">
                        Owner and Data controller - Blackbird Lab, LLC <br />
                        email:
                        <a className="privacy-policy__link" href="mailto:info@blackbird-lab.com">
                            info@blackbird-lab.com
                        </a>
                    </TextDescription>
                    <SectionTitle type="primary" title="Types of Data collected" />
                    <TextDescription type="mb">
                        Among the types of Personal Data that this Website collects, by itself or
                        through third parties, there are Cookies, Usage Data, unique device
                        identifiers for advertising (Google Advertiser ID or IDFA, for example),
                        email address, phone number, first name, last name, company name, and
                        country.
                    </TextDescription>
                    <TextDescription type="mb">
                        Complete details on each type of Personal Data collected are provided in the
                        dedicated sections of this privacy policy or by specific explanation texts
                        displayed prior to the Data collection. Personal Data may be freely provided
                        by the User, or, in the case of Usage Data, collected automatically when
                        using this Website.
                    </TextDescription>
                    <TextDescription type="mb">
                        Unless specified otherwise, all Data requested by this Website is mandatory
                        and failure to provide this Data may make it impossible for this Website to
                        provide its services. In cases where this Website specifically states that
                        some Data is not mandatory, Users are free not to communicate this Data
                        without consequences to the availability or the functioning of the Service.
                    </TextDescription>
                    <TextDescription type="mb">
                        Users who are uncertain about which Personal Data is mandatory are welcome
                        to contact the Owner.
                    </TextDescription>
                    <TextDescription type="mb">
                        Any use of Cookies – or of other tracking tools – by this Website or by the
                        owners of third-party services used by this Website serves the purpose of
                        providing the Service required by the User, in addition to any other
                        purposes described in the present document and in the Cookie Policy, if
                        available.
                    </TextDescription>
                    <TextDescription type="mb">
                        Users are responsible for any third-party Personal Data obtained, published
                        or shared through this Website and confirm that they have the third party's
                        consent to provide the Data to the Owner.
                    </TextDescription>
                    <SectionTitle type="primary" title="The legal basis of the processing" />
                    <TextDescription type="mb">
                        The Owner may process Personal Data relating to Users if one of the
                        following applies:
                    </TextDescription>
                    <TextDescription type="mb">
                        • Users have given their consent for one or more specific purposes. Note:
                        Under some legislation, the Owner may be allowed to process Personal Data
                        until the User objects to such processing (“opt-out”), without having to
                        rely on consent or any other of the following legal bases. This, however,
                        does not apply, whenever the processing of Personal Data is subject to
                        European data protection law;
                    </TextDescription>
                    <TextDescription type="mb">
                        • provision of Data is necessary for the performance of an agreement with
                        the User and/or for any pre-contractual obligations thereof;
                    </TextDescription>
                    <TextDescription type="mb">
                        • processing is necessary for compliance with a legal obligation to which
                        the Owner is subject;
                    </TextDescription>
                    <TextDescription type="mb">
                        • processing is related to a task that is carried out in the public interest
                        or in the exercise of official authority vested in the Owner;
                    </TextDescription>
                    <TextDescription type="mb">
                        • processing is necessary for the purposes of the legitimate interests
                        pursued by the Owner or by a third party.
                    </TextDescription>
                    <TextDescription type="mb">
                        In any case, the Owner will gladly help to clarify the specific legal basis
                        that applies to the processing, and in particular whether the provision of
                        Personal Data is a statutory or contractual requirement, or a requirement
                        necessary to enter into a contract.
                    </TextDescription>
                    <SectionTitle type="primary" title="Place" />
                    <TextDescription type="mb">
                        The Data is processed at the Owner's operating offices and in any other
                        places where the parties involved in the processing are located.
                    </TextDescription>
                    <TextDescription type="mb">
                        Depending on the User's location, data transfers may involve transferring
                        the User's Data to a country other than their own. To find out more about
                        the place of processing of such transferred Data, Users can check the
                        section containing details about the processing of Personal Data. If broader
                        protection standards are applicable, Users are also entitled to learn about
                        the legal basis of Data transfers to a country outside the European Union or
                        to any international organization governed by public international law or
                        set up by two or more countries, such as the UN, and about the security
                        measures taken by the Owner to safeguard their Data.
                    </TextDescription>
                    <TextDescription type="mb">
                        If any such transfer takes place, Users can find out more by checking the
                        relevant sections of this document or inquire with the Owner using the
                        information provided in the contact section.
                    </TextDescription>
                    <SectionTitle type="primary" title="Retention time" />
                    <TextDescription type="mb">
                        Personal Data shall be processed and stored for as long as required by the
                        purpose they have been collected for.
                    </TextDescription>
                    <TextDescription type="mb">
                        The Owner may be allowed to retain Personal Data for a longer period
                        whenever the User has given consent to such processing, as long as such
                        consent is not withdrawn. Furthermore, the Owner may be obliged to retain
                        Personal Data for a longer period whenever required to do so for the
                        performance of a legal obligation or upon the order of an authority.
                    </TextDescription>
                    <TextDescription type="mb">
                        Once the retention period expires, Personal Data shall be deleted.
                        Therefore, the right to access, the right to erasure, the right to
                        rectification and the right to data portability cannot be enforced after the
                        expiration of the retention period.
                    </TextDescription>
                    <SectionTitle type="primary" title="The purposes of processing" />
                    <TextDescription type="mb">
                        The Data concerning the User is collected to allow the Owner to provide its
                        Services, as well as for the following purposes: Analytics, Advertising,
                        User database management, Managing contacts and sending messages and
                        Contacting the User.
                    </TextDescription>
                    <TextDescription type="mb">
                        Users can find further detailed information about such purposes of
                        processing and about the specific Personal Data used for each purpose in the
                        respective sections of this document.
                    </TextDescription>
                    <TextDescription type="mb">
                        Personal Data is collected for the following purposes and using the
                        following services:
                    </TextDescription>
                    <SectionTitle type="policy" title="Google Analytics (Google LLC)" />
                    <TextDescription type="mb">
                        Google Analytics is a web analysis service provided by Google LLC
                        (“Google”). Google utilizes the Data collected to track and examine the use
                        of this Website, to prepare reports on its activities and share them with
                        other Google services. Google may use the Data collected to contextualize
                        and personalize the ads of its own advertising network. Personal Data
                        collected: Cookies and Usage Data. <br />
                        Place of processing: United States –
                        <a
                            className="privacy-policy__link"
                            href="https://policies.google.com/privacy"
                        >
                            Privacy Policy
                        </a>
                        -
                        <a
                            className="privacy-policy__link"
                            href="https://tools.google.com/dlpage/gaoptout?hl=en"
                        >
                            Opt Out
                        </a>
                        Privacy Shield participant.
                    </TextDescription>
                    <SectionTitle type="policy" title="HubSpot Analytics (HubSpot, Inc.)" />
                    <TextDescription type="mb">
                        HubSpot Analytics is an analytics service provided by HubSpot, Inc. Personal
                        Data collected: Cookies and Usage Data.
                        <br />
                        Place of processing: United States –
                        <a
                            className="privacy-policy__link"
                            href="https://legal.hubspot.com/privacy-policy"
                        >
                            Privacy Policy
                        </a>
                        - Opt Out
                    </TextDescription>
                    <SectionTitle type="policy" title="Contact form (this Website)" />
                    <TextDescription type="mb">
                        By filling in the contact form with their Data, the User authorizes this
                        Website to use these details to reply to requests for information, quotes or
                        any other kind of request as indicated by the form’s header.
                        <br />
                        Personal Data collected: email address, first name, and last name.
                    </TextDescription>
                    <TextDescription type="mb">
                        This type of service allows the Owner to build user profiles by starting
                        from an email address, a personal name, or other information that the User
                        provides to this Website, as well as to track User activities through
                        analytics features. This Personal Data may also be matched with publicly
                        available information about the User (such as social networks' profiles) and
                        used to build private profiles that the Owner can display and use for
                        improving this Website.
                    </TextDescription>
                    <TextDescription type="mb">
                        Some of these services may also enable the sending of timed messages to the
                        User, such as emails based on specific actions performed on this Website.
                    </TextDescription>
                    <SectionTitle type="policy" title="HubSpot CRM (HubSpot, Inc.)" />
                    <TextDescription type="mb">
                        HubSpot CRM is a User database management service provided by HubSpot, Inc.
                        Personal Data collected: email address, phone number and various types of
                        Data as specified in the privacy policy of the service.
                        <br />
                        Place of processing: United States –
                        <a
                            className="privacy-policy__link"
                            href="https://legal.hubspot.com/privacy-policy"
                        >
                            Privacy Policy
                        </a>
                        Privacy Shield participant.
                    </TextDescription>
                    <SectionTitle type="primary" title="The rights of Users" />
                    <TextDescription type="mb">
                        Users may exercise certain rights regarding their Data processed by the
                        Owner. Users entitled to broader protection standards may exercise any of
                        the rights described below. In all other cases, Users may inquire with the
                        Owner to find out which rights apply to them.
                    </TextDescription>
                    <TextDescription type="mb">
                        In particular, Users have the right to do the following:
                    </TextDescription>
                    <TextDescription type="mb">
                        • Withdraw their consent at any time. Users have the right to withdraw
                        consent where they have previously given their consent to the processing of
                        their Personal Data.
                    </TextDescription>
                    <TextDescription type="mb">
                        • Object to the processing of their Data. Users have the right to object to
                        the processing of their Data if the processing is carried out on a legal
                        basis other than consent. Further details are provided in the dedicated
                        section below.
                    </TextDescription>
                    <TextDescription type="mb">
                        • Verify and seek rectification. Users have the right to verify the accuracy
                        of their Data and ask for it to be updated or corrected.
                    </TextDescription>
                    <TextDescription type="mb">
                        • Restrict the processing of their Data. Users have the right, under certain
                        circumstances, to restrict the processing of their Data. In this case, the
                        Owner will not process their Data for any purpose other than storing it.
                    </TextDescription>
                    <TextDescription type="mb">
                        • Have their Personal Data deleted or otherwise removed. Users have the
                        right, under certain circumstances, to obtain the erasure of their Data from
                        the Owner.
                    </TextDescription>
                    <TextDescription type="mb">
                        • Receive their Data and have it transferred to another controller. Users
                        have the right to receive their Data in a structured, commonly used and
                        machine-readable format and, if technically feasible, to have it transmitted
                        to another controller without any hindrance. This provision is applicable
                        provided that the Data is processed by automated means and that the
                        processing is based on the User's consent, on a contract which the User is
                        part of or on pre-contractual obligations thereof.
                    </TextDescription>
                    <TextDescription type="mb">
                        • Lodge a complaint. Users have the right to bring a claim before their
                        competent data protection authority.
                    </TextDescription>
                    <TextDescription type="mb">
                        Where Personal Data is processed for a public interest, in the exercise of
                        an official authority vested in the Owner or for the purposes of the
                        legitimate interests pursued by the Owner, Users may object to such
                        processing by providing a ground related to their particular situation to
                        justify the objection.
                    </TextDescription>
                    <SectionTitle
                        type="policy"
                        title="Additional information about User's Personal Data"
                    />
                    <TextDescription type="mb">
                        In addition to the information contained in this privacy policy, this
                        Website may provide the User with additional and contextual information
                        concerning particular Services or the collection and processing of Personal
                        Data upon request. In addition, since the Owner values your time and effort
                        for reading this Privacy Policy, the Owner will pay two hundred US dollars
                        to the first person who notices this text. Contact the Owner for the money
                        reward. The reward is eligible for anyone who’s not employed by the Owner
                        and is not the contractor of the Owner.
                    </TextDescription>
                    <SectionTitle type="policy" title="Changes to this privacy policy" />
                    <TextDescription type="mb">
                        The Owner reserves the right to make changes to this privacy policy at any
                        time by giving notice to its Users on this page and possibly within this
                        Website and/or - as far as technically and legally feasible - sending a
                        notice to Users via any contact information available to the Owner. It is
                        strongly recommended to check this page often, referring to the date of the
                        last modification listed at the bottom.
                    </TextDescription>
                </div>
            </section>
            <FixedButton />
        </Layouts>
    );
};

export default AcknowledgementAndAcceptance;
