import React, { FC } from 'react';
import video from '../../../assets/video/video.mp4';

import { useGetScreenWidth } from '../../../hooks/useGetScreenWidth';
import './AnimatedVideo.scss';

interface AnimatedVideo {
    video?: string;
    type?: string;
}

const AnimatedVideo: FC<AnimatedVideo> = (props) => {
    const isMobile = useGetScreenWidth();
    return (
        <div className="video__container">
            <div data-testid="video" className={`video video--${props.type}`}>
                <div className="video__background">
                    <video
                        className="video__video"
                        src={props.video ? props.video : video}
                        autoPlay={isMobile ? false : true}
                        muted={true}
                        loop={true}
                        controls={false}
                    />
                </div>
                <div className="video__wrapper">
                    <p className="video__slogan" />
                    <span className="video__name" />
                </div>
            </div>
        </div>
    );
};

export default AnimatedVideo;
