import React, { FC } from 'react';
import { Link } from 'gatsby';
import SmallFooter from '../SecondaryFooter';
import './Footer.scss';
import blackBird from '../../../assets/img/bird-logo.svg';

const Footer: FC = () => {
    const pickAnExpertiseLinks = [
        { id: 0, title: 'What We Do', page: '/what-we-do/' },
        { id: 1, title: 'How We Work', page: '/how-we-work/' },
        { id: 2, title: 'Engagement Models', page: '/how-we-work/engagement-models/' },
        { id: 3, title: 'Intellectual Property', page: '/how-we-work/intellectual-property/' },
        { id: 4, title: 'Blog', page: '/blog/' },
    ];

    const companies = [
        { id: 0, title: 'About Us', page: '/about-us/' },
        { id: 1, title: 'Contacts', page: '/contact-us/' },

        {
            id: 2,
            title: 'Careers',
            label: 'Hiring',
            isLabled: true,
            link: 'https://careers.blackbird-lab.com/en-GB',
        },
    ];

    return (
        <footer data-testid="footer" className="footer">
            <div className="footer__wrapper">
                <div className="footer__info">
                    <div className="footer__links">
                        <Link to="/" className="link">
                            <img src={blackBird} alt="" className="footer__logo" />
                        </Link>
                        <div className="footer__social-wrapper">
                            <a
                                target="_blank"
                                href="https://ua.linkedin.com/company/blackbird-lab"
                                className="footer__social"
                                rel="noreferrer"
                            >
                                in
                            </a>
                            <div className="footer__bar" />
                            <a
                                target="_blank"
                                href="https://jobs.dou.ua/companies/blackbird-lab/"
                                className="footer__social"
                                rel="noreferrer"
                            >
                                DOU
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer__functions">
                    <ul className="footer__lists">
                        <span className="footer__small-title">Learn about us</span>
                        {pickAnExpertiseLinks.map((expertise) => (
                            <Link key={expertise.id} to={expertise.page}>
                                <li className="footer__link footer__link--lg">{expertise.title}</li>
                            </Link>
                        ))}
                    </ul>
                    <ul className="footer__lists footer__lists--mt">
                        <span className="footer__small-title">Company</span>
                        {companies.map((company) => (
                            <Link key={company.id} to={company.page}>
                                <a href={company.link} target="_blank" rel="noreferrer">
                                    <li className="footer__link">
                                        {company.title}
                                        {company.isLabled && (
                                            <span className="footer__label">{company.label}</span>
                                        )}
                                    </li>
                                </a>
                            </Link>
                        ))}
                    </ul>
                </div>
            </div>
            <SmallFooter />
        </footer>
    );
};

export default Footer;
