import React, { FC } from 'react';
import triangleRight from '../../../../assets/img/triangle-right.svg';
import Button from '../../../common/Button';
import arrowRight from '../../../../assets/img/arr-right.svg';
import TextDescription from '../../../common/TextDescription';

const Title: FC = () => {
    return (
        <div data-testid="title" className="main-title__body">
            <div className="main-title__wrapper">
                <div className="main-title__container">
                    <h2 className="main-title">
                        Your Superior Team <span className="main-title__span">to</span>
                    </h2>
                </div>
            </div>
            <div className="main-title__text">
                <TextDescription>We develop effective technology solutions</TextDescription>
            </div>
            <div className="main-title__desktop-button">
                <a
                    className="main-title__desktop-link"
                    href="https://clutch.co/profile/blackbird-lab#reviews"
                    target="_blank"
                    rel="noreferrer"
                >
                    Visit our Clutch profile
                    <img
                        className="main-title__desktop-img"
                        src={triangleRight}
                        alt="Visit our Clutch profile"
                    />
                </a>
            </div>
        </div>
    );
};

export default Title;
