import React, { FC } from 'react';

import { generateCdnUrl } from '../utils';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import CookiesPopup from '../components/Cookies';
import Title from '../components/pagesComponents/MainPage/Title';
import Services from '../components/pagesComponents/MainPage/Services';
import Technologies from '../components/pagesComponents/MainPage/Technologies';
import Features from '../components/pagesComponents/MainPage/Features';
import AnimatedVideo from '../components/common/AnimatedVideo';
import TextSpan from '../components/pagesComponents/MainPage/TextSpan';
import FixedButton from '../components/common/FixedButton';

import '../styles/index.scss';

const HomePage: FC = () => (
    <Layout>
        <SEO
            pageTitle="Blackbird Lab"
            pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
            pageImage={generateCdnUrl('/main-page/main-page.png')}
        />
        <main className="main">
            <div className="main__wrapper">
                <Title />
                <Services />
                <Technologies showIcons={true} />
                <Features
                    localLink={true}
                    globalLink={false}
                    buttonIcon={generateCdnUrl('/assets/arrows/arr-right.svg')}
                    title="More details on our service delivery and how we set up to success"
                    buttonText="Discover"
                />
                <AnimatedVideo logo={generateCdnUrl('/assets/companies/spectar-black.svg')} />
            </div>
            <TextSpan
                buttonLink="https://calendly.com/ydrozd"
                buttonText="Book a meeting"
                title="Let’s work together"
                buttonIcon={generateCdnUrl('/assets/icons/calendar.svg')}
            >
                Even a 5-minute intro call could potentially save you countless hours of endless
                back-and-forth emails.
            </TextSpan>
        </main>
        <CookiesPopup />
        <FixedButton />
    </Layout>
);

export default HomePage;
